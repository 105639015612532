<script setup>
import { WdsButtonLink, WdsRatingBreakdown, WdsReviewCard } from '@wds/components';

const props = defineProps({
  tag: {
    type: String,
    required: false,
    default: 'section',
  },
  propertyRating: {
    type: Object,
    required: false,
    default: () => ({}),
  },
  propertyLatestReviews: {
    type: Object,
    required: false,
    default: () => ({}),
  },
});

const emit = defineEmits(['displayModal']);

// COMPOSABLES
const { useRating } = useRatings();

const ratingScoreTranslations = toValue(useRating?.getRatingScoreKeywordTranslations);

const ratingBreakdown = useRating?.getRatingBreakdown(props.propertyRating);

function toggleReviewsPopup() {
  emit('displayModal', 'reviews');
}
</script>

<template>
  <component :is="tag">
    <h2 class="rating-and-reviews-title">
      {{ $t('t_REVIEWS') }}
    </h2>
    <div class="rating-and-reviews-wrapper">
      <div class="rating-wrapper">
        <WdsRatingBreakdown
          :city-info="ratingBreakdown"
          :translations="ratingScoreTranslations"
          :rating-translations="ratingScoreTranslations"
          :card-layout="false"
          :enable-collapse="false"
          :is-collapsed="false"
        />
        <WdsButtonLink
          href="#reviews"
          class="reviews-view-all desktop"
          rel="noopener"
          icon-end="chevron-right"
          :text="$t('t_VIEWREVIEWS')"
          :aria-label="$t('t_VIEWREVIEWS')"
          :title="$t('t_VIEWREVIEWS')"
          @click.prevent="toggleReviewsPopup"
        />
      </div>
      <div class="reviews-wrapper">
        <div class="reviews-cards-list">
          <WdsReviewCard
            v-for="(review, idx) in propertyLatestReviews"
            :key="`latest-review-${idx}`"
            class="latest-review-card"
            :text="review.text"
            :stayed-in="review.date"
            :rating-score="review.rating"
            :user-info="review.author"
            :show-as-card="true"
            :truncate-text="true"
            :show-read-more="true"
            :expand-on-read-more="true"
          />
        </div>
        <WdsButtonLink
          href="#reviews"
          class="reviews-view-all mobile"
          rel="noopener"
          icon-end="chevron-right"
          :text="$t('t_VIEWREVIEWS')"
          :aria-label="$t('t_VIEWREVIEWS')"
          :title="$t('t_VIEWREVIEWS')"
          @click.prevent="toggleReviewsPopup"
        />
      </div>
    </div>
  </component>
</template>

<style scoped lang="scss">
/*
.rating-and-reviews-title {
  padding: 0 $wds-spacing-m;

  @include tablet {
    padding-inline: $wds-spacing-l;
  }
}
*/

.rating-and-reviews-wrapper {
  display: flex;
  flex-direction: column;
  gap: $wds-spacing-s;

  .reviews-view-all {
    cursor: pointer;

    &.mobile {
      display: flex;
      align-self: flex-end;
      margin-top: $wds-spacing-s;
    }

    &.desktop {
      display: none;
      margin-top: $wds-spacing-m;
    }
  }

  .rating-wrapper {
    :deep(.rating-breakdown-container .rating-container) {
      margin-bottom: 0;
      width: auto;
    }
  }

  .reviews-wrapper {
    display: flex;
    flex-direction: column;
    background-color: $wds-color-sunset-light;
    padding: wds-rem(40px) 0 $wds-spacing-m;
    margin-inline: -$wds-spacing-m;
    margin-top: $wds-spacing-s;
    clip-path: polygon(0 5%, 100% 0, 100% 100%, 0 95%);

    .reviews-cards-list {
      display: flex;
      flex-direction: row;
      align-items: stretch;
      gap: $wds-spacing-m;
      width: 100%;
      overflow-x: auto;
      padding-inline: $wds-spacing-m;

      .latest-review-card {
        min-width: wds-rem(288px);
        min-height: auto;
      }
    }
  }

  @include tablet {
    .reviews-wrapper {
      margin-inline: -$wds-spacing-l;
    }
  }

  @include tablet-large {
    .reviews-wrapper {
      margin-inline: 0;
    }
  }

  @include desktop {
    min-height: wds-rem(415px);
    flex-direction: row;
    gap: $wds-spacing-l;

    // padding-inline: $wds-spacing-l;

    .reviews-view-all {
      &.mobile {
        display: none;
      }

      &.desktop {
        display: flex;
      }
    }

    .rating-wrapper {
      flex: 0 0 wds-rem(256px);
      padding-inline: 0;

      :deep(.rating-breakdown-container .rating-container) {
        width: auto;
      }
    }

    .reviews-wrapper {
      flex: 1;
      flex-direction: column;
      background-color: $wds-color-sunset-light;
      border-radius: wds-rem(40px);
      padding: $wds-spacing-l;
      clip-path: initial;

      .reviews-cards-list {
        padding-inline: 0;
        flex-direction: column;

        .latest-review-card {
          width: 100%;
          min-width: wds-rem(244px);
        }
      }
    }
  }

  @include desktop-large {
    .rating-wrapper {
      flex-basis: wds-rem(360px);
    }
  }
}
</style>
