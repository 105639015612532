<script setup>
import { WdsButtonLink } from '@wds/components';

defineProps({
  tag: {
    type: String,
    required: false,
    default: 'section',
  },
  checkInTime: {
    type: Object,
    required: true,
  },
  checkOutTime: {
    type: Object,
    required: true,
  },
  additionalRules: {
    type: Array,
    required: false,
    default: () => [],
  },
});

// EVENTS
const emit = defineEmits(['viewMore']);

// METHODS
function toggleHouseRulesPopup() {
  emit('viewMore', 'houserules');
}
</script>

<template>
  <component
    :is="tag"
    class="house-rules-container"
  >
    <h2 class="house-rules-title">
      {{ $t('t_HOUSERULES') }}
    </h2>
    <PropertyHwHouseRulesStayPolicy
      :additional-rules="additionalRules"
      :check-in-time="checkInTime"
      :check-out-time="checkOutTime"
    />
    <WdsButtonLink
      :aria-label="$t('t_VIEWHOUSERULES')"
      :text="$t('t_VIEWHOUSERULES')"
      :title="$t('t_VIEWHOUSERULES')"
      class="house-rules-view-all"
      href="#house-rules"
      icon-end="chevron-right"
      rel="noopener"
      @click.prevent="toggleHouseRulesPopup"
    />
  </component>
</template>

<style lang="scss" scoped>
.house-rules-container {
  display: flex;
  flex-direction: column;

  .house-rules-view-all {
    cursor: pointer;
    align-self: flex-end;
    margin-top: $wds-spacing-m;
  }
}
</style>
