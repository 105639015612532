<script setup>
import { WdsButtonGhost, WdsIcon } from '@wds/components';

const props = defineProps({
  tag: {
    type: String,
    required: false,
    default: 'section',
  },
  propertyLocation: {
    type: Object,
    required: true,
  },
});

const emit = defineEmits(['displayModal']);
const propertyAddress = computed(() => {
  if (!props.propertyLocation?.address) {
    return '';
  }

  const {
    address: {
      line1,
      line2,
    },

  } = props.propertyLocation;
  return [line1, line2].filter((l) => l).join(', ');
});

const roundedDistance = computed(() => {
  return Math.round(props.propertyLocation.cityCenterDistance * 10) / 10;
});

function copyAddress() {
  nextTick(() => {
    navigator.clipboard
      .writeText(`${propertyAddress.value}, ${props.propertyLocation.city.name}`)
      .then(() => {
        console.log('text copied');
        // @TODO check later what to do with this as we need new designs for the TOAST (with butter)
        // $store.commit('toast/show', {
        //   message: $t('t_SUCCESS'),
        //   type: 'success',
        // });
      })
      .catch((error) => {
        console.error('Error copying to clipboard:', error);
      });
  });
}

function handleMapModal() {
  emit('displayModal', 'location');
}
</script>

<template>
  <component :is="tag">
    <div class="location-wrapper">
      <h2 class="location-title">
        {{ $t('t_LOCATION') }}
      </h2>
      <div class="location-details">
        <div class="location-address-and-distance">
          <div class="location-address">
            <WdsIcon asset="location-pin" />
            <span class="address-text">
              {{ `${propertyAddress}${propertyLocation.city.name && ','}` }}
              {{ `${propertyLocation.city.name}${propertyLocation.country.name && ','}` }}
              {{ propertyLocation.country.name }}
            </span>
            <WdsIcon
              asset="copy"
              class="address-copy"
              @click="copyAddress"
            />
          </div>
          <div class="location-distance">
            <WdsIcon asset="city" />
            <span>{{ $t('t_FROMCITYCENTER', { DISTANCE: roundedDistance }) }}</span>
          </div>
        </div>
        <div class="location-view-on-map">
          <WdsButtonGhost
            :text="$t('t_VIEWMAP')"
            icon-end="chevron-right"
            @click="handleMapModal"
          />
        </div>
      </div>
    </div>
  </component>
</template>

<style scoped lang="scss">
.location-wrapper {
  display: flex;
  flex-direction: column;
  gap: $wds-spacing-s;

  .location-details {
    display: flex;
    flex-direction: column;
    gap: $wds-spacing-s;

    .location-address-and-distance {
      display: flex;
      flex-direction: column;
      gap: $wds-spacing-s;

      @include body-2-reg;

      .location-address {
        display: flex;
        align-items: center;
        gap: $wds-spacing-s;

        .address-text {
          flex: 1;
        }

        .address-copy {
          cursor: pointer;
        }
      }

      .location-distance {
        display: flex;
        gap: $wds-spacing-s;
      }
    }

    .location-view-on-map {
      display: flex;
      align-items: center;
      justify-content: center;
      height: wds-rem(100px);
      background-image: url('@/public/map-horizontal.svg');
      background-repeat: no-repeat;
      border-radius: $wds-border-radius-m;
      box-shadow: inset 0 0 0 wds-rem(1px) $wds-color-ink-lighter;
    }
  }

  @include desktop {
    .location-details {
      flex-direction: row;
      gap: $wds-spacing-l;

      .location-address-and-distance {
        flex: 0 0 wds-rem(256px);
      }

      .location-view-on-map {
        flex: 1;
      }
    }
  }

  @include desktop-large {
    .location-details .location-address-and-distance {
      flex-basis: wds-rem(360px);
    }
  }
}
</style>
